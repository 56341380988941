import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import ScrollspyNav from 'react-scrollspy-nav';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { Row, Col, Card } from 'react-bootstrap';
import { AshCharacter } from '../../../modules/ash/components/ash-character';
import { AshTrace } from '../../../modules/ash/components/ash-trace';

const AshGuidesSYPScar: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ash-guide'} game="ash">
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/ash-echoes/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Should You Pull Scarlett?</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ash/categories/category_scar.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Should You Pull Scarlett?</h1>
          <h2>
            A dedicated guide for Scarlett that will help you decide whether to
            pull her or not.
          </h2>
          <p>
            Last updated: <strong>27/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Introduction" />
              <p>
                <strong>Scarlett is the main carry of the Wind team.</strong>
                She’s the queen of “Echo Hunt”, the Club-based boss challenge.
                If you’re into competition, definitely consider getting her. For
                non-competitive players, she's nevertheless a good and versatile
                pick for newbies, with easy to understand mechanics. While Wind
                doesn’t take part in elemental reactions, it does have its own
                set of unique traits, such as additional damage against airborne
                enemies, knock-backs and the ability to clear poison mist.
                Scarlett takes advantage of all that in her kit, and adds to it
                with a natural increase in ASPD and being able to ignore a
                portion of enemy DR (damage reduction). Furthermore, with her
                second skill, she also has the unique capability of acting as a
                pseudo-tank in crucial moments, as that skill increases her own
                DR by 90%.
              </p>
              <StaticImage
                src="../../../images/ash/pull/scar_1.webp"
                alt="Guides"
              />
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Review" />
              <p>
                As the main carry of the Wind Team (with no replacement in sight
                as of the writing of this article), Scarlett doesn’t use a bike
                just for show. She’s all about speed, being able to unleash high
                bursts from a long distance in a moment, and be in the face of
                the enemy in the next. She comes and goes like the wind, and has
                the unique capability of acting as a pseudo-tank with her second
                skill, “Relentless Pursuit”. This allows the team to bring an
                extra DPS or buffer to the field, thus changing the usual
                dynamic and opening an array of possibilities.
              </p>
              <p>
                The key mechanic that guides Scarlett’s performance in battle is
                her [Arrest Mode]. After using her Seed Skill, “Crimson
                Punisher”, Scarlett enters Arrest Mode for 30 seconds, greatly
                enhancing her other skills and making her basic attacks bounce
                on targets. Thankfully, this ultimate has a relatively low
                cooldown (60 seconds), and that cooldown is halved for the first
                cast. This makes it easy to wait until it’s ready and use
                “Crimson Punisher” as an opening before following with a combo
                of “Heaven Piercer”, now with increased width and an extra 20%
                damage bonus on the second cast, granting a huge burst.
              </p>
              <StaticImage
                src="../../../images/ash/pull/scar_2.webp"
                alt="Guides"
              />
              <p>
                “Relentless Pursuit” is the other half of R.E.D.’s Captain.
                Passively, it increases Scarlett’s ASPD by 50% every 20s,
                lasting 15s. It has five active uses, in which Scarlett locks
                into a target, reduces their Mov. Speed by 90%, DR by up to 20%,
                knocks them around and increases her own DR by 90%. She also
                deals damage while relentlessly bullying the target and gaining
                immunity to control effects. As if that wasn't already enough,
                in the case that Scarlett's 50 bullets haven’t been spent during
                this channel, “Relentless Pursuit” activates an additional layer
                of buffs to Scarlett; This allows her basic attacks to ignore
                20% of all enemies DR for the next 45 seconds.
              </p>
              <p>
                Fast and Furious is certainly one way to describe Scarlett’s
                gameplay. To summarize her rotation: Keep her distant from
                enemies, charge her seed skill, after casting it, combo with
                “Heaven Piercer”; Whenever a boss or elite enemy shows up, close
                in with “Relentless Pursuit”, then return to the backline.
              </p>
              <StaticImage
                src="../../../images/ash/pull/scar_3.webp"
                alt="Guides"
              />
              <h5>Pros & Cons</h5>
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Self-Reliant, can fit in most teams as an anti-air
                          unit who can reduce enemy DR.
                        </li>
                        <li>Seed skill has Barrier PEN.</li>
                        <li>
                          Great uptime on passively increasing her own ASPD by
                          50%.
                        </li>
                        <li>
                          Still the best main carry for “Echo Hunt” competitive
                          mode.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Despite being versatile, she relies on being on a Wind
                          Team to make use of her full potential.
                        </li>
                        <li>
                          Either excels at certain bosses or struggles, with no
                          in-between.
                        </li>
                        <li>
                          Optimal building can be a bit expensive due to being
                          reliant on two SSR Traces (Blood Ruvia Secret + Dusk
                          Radiance).
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Gameplay Tips" />
              <h5>General Tips</h5>
              <ul>
                <li>
                  Try to prioritize charging Scarlett’s ult before using her
                  skills whenever possible, as it will fully buff her for 30s.
                  This makes Freda with 3 dupes undoubtedly the best healer
                  choice for her. You can also use skills like “Seed Charge —
                  Triangle β” from the Memory Trace “Karma” to speed up
                  Scarlett’s rotation.
                </li>
                <li>
                  For most of the fight, try to keep Scarlett as far away from
                  the enemies as possible. She has a very long range on her
                  auto-attacks and first skill, “Heaven Piercer”, and can take
                  full advantage of several Trace skills based on distance, such
                  as “Snipe” (Muscle Building), “Keep Out - Triangle” (Home) and
                  “Out of Range” (Karma). Thanks to her high mobility, she can
                  easily jump in for “Relentless Pursuit” and “Crimson Punisher”
                  at key moments and then move away.
                </li>
              </ul>
              <h5>Dupes</h5>
              <p>
                <strong>
                  F2P players can get the full Scarlett experience at 0 dupes.
                </strong>{' '}
                For competitive players, both her dupe 1 and 3 add significant
                improvement. If you are really into Guild/Club competitive mode,
                you can invest depending on your budget.
              </p>
              <h5>Skill Upgrade Priority</h5>
              <ul>
                <li>
                  Skill 1, “Heaven Piercer” is the best cost-benefit for general
                  use, since it’s where Scarlett’s burst damage comes from.
                </li>
                <li>
                  Skill 2, “Relentless Pursuit” is mainly used for its DR
                  reduction on enemies and DR buff on Scarlett, but it doesn’t
                  increase with leveling the skill. Save your materials and
                  upgrade it only after you’re done with “Heaven Piercer”.
                </li>
              </ul>
              <StaticImage
                src="../../../images/ash/pull/scar_4.webp"
                alt="Guides"
              />
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Team Recommendation" />
              <h5>Leader: Scarlett</h5>
              <div className={`skills Wind`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Wind`}>Leader Skill</div>
                        <div className="skill-info">
                          <p className="skill-name">
                            Razor Edge - Triangle - β
                          </p>
                          <p className="skill-type">
                            Classes: <strong>Skirmisher, Ranger</strong>
                          </p>
                        </div>
                      </div>
                      <div className={`potential`}>
                        <p>
                          Increases Extra DMG by 10/17.5/25%. The effect is
                          doubled in a [Wet Environment]. It does not stack with
                          [Razor Edge - Triangle - ɑ].
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className={`skills Wind`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Wind`}>Leader Skill</div>
                        <div className="skill-info">
                          <p className="skill-name">Rampaging Tempest</p>
                          <p className="skill-type">
                            Classes: <strong>Skirmisher, Ranger</strong>
                          </p>
                        </div>
                      </div>
                      <div className={`potential`}>
                        <p>
                          Casting a Seed Skill increases the Wind DMG of all
                          allied Echomancers in the team by 25/50/75% for 60s.
                          This effect does not stack.Triggering this effect
                          again will refresh the duration.
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <p>
                NOTE: Remember you can mix and match Echomancers among all
                suggestions and alternate options, adjusting based on who you
                have available.
              </p>
              <p>
                NOTE 2: If you don’t have “Blood Ruvia Secret” make sure to
                borrow it.
              </p>
              <div className={`character-teams box Wind`}>
                <div className="team-header">
                  <p>RELENTLESS ATTACKS</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="scarlett"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Main DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="shiratori-azusa"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Sub-DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="begonia"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role tank">Sub-DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="freda"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                A very aggressive but self-reliant team. It maximizes damage
                potential from all fronts, with Scarlett reducing enemy DR,
                Azusa reducing enemy’s Elemental RES and Begonia increasing the
                team’s ASPD. Freda adds the cherry on the cake with her myriad
                of buffs and healing. During most of the fight, Azusa and
                Begonia should split the frontline to make managing damage
                easier. When dealing with elite enemies or bosses, Scarlett
                takes the frontline instead, by activating her skill “Relentless
                Pursuit”.
              </p>
              <div className={`character-teams box Wind`}>
                <div className="team-header">
                  <p>DEFENSIVE PURSUIT</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="scarlett"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Main DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="shiratori-azusa"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Sub DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="lorelle"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role tank">Tank</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="longqing"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                A well-balanced team with a solid frontline and a more defensive
                approach. In this team, instead of focusing on ASPD, Lorelle
                will provide Extra DMG bonus and block gauge breaking through
                her Polyphony passive, while Longqing can quickly stack Inspire
                with “Nightmare”. She can be switched to Freda after reaching
                full Inspire stacks for more ATK buffs.
              </p>
            </div>
            <div className={`character-teams box Wind`}>
              <div className="team-header">
                <p>CRIMSON WIND</p>
              </div>
              <div className="single-team">
                <div className="team-row">
                  <div className="with-role">
                    <div className="character main">
                      <Card className="avatar-card">
                        <AshCharacter
                          slug="scarlett"
                          mode="card"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <div className="role dps">Main DPS</div>
                  </div>
                  <div className="with-role">
                    <div className="character main">
                      <Card className="avatar-card">
                        <AshCharacter
                          slug="shiratori-azusa"
                          mode="card"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <div className="role dps">Sub DPS</div>
                  </div>
                  <div className="with-role">
                    <div className="character main">
                      <Card className="avatar-card">
                        <AshCharacter
                          slug="felicio"
                          mode="card"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <div className="role support">Buffer</div>
                  </div>
                  <div className="with-role">
                    <div className="character main">
                      <Card className="avatar-card">
                        <AshCharacter
                          slug="zodivin-azure"
                          mode="card"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />
                      </Card>
                    </div>
                    <div className="role support">Support</div>
                  </div>
                </div>
              </div>
            </div>
            <p>
              Zodivin is not mandatory here, and isn’t arguably the best choice,
              but I wanted to showcase an option where she can shine. Her
              healing over time paired with her DR buff on square characters
              facilitates the survival of Azusa on the frontline between the
              cooldowns of Scarlett’s “Relentless Pursuit”. Meanwhile, Felicio
              benefits from the increased Wind DMG that enemies will take,
              increases the team ASPD, as well as being a good source of
              anti-air cleansing.
            </p>
            <h5>More Alternate and Backup Options</h5>
            <ul>
              <li>
                <AshCharacter
                  slug="sambheka"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />{' '}
                <strong>[Specialist]</strong> - Sambheka can keep large waves of
                enemies at bay, all while doing respectable True DMG when paired
                with the Memory Trace “Dawn”. A good option to have as backup at
                any time.
              </li>
              <li>
                <AshCharacter
                  slug="hassel"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />{' '}
                <strong>[DPS]</strong> - (Or any other Skirmisher). Can be used
                to quickly stack Inspire through “Combo Mastery”, and benefits
                from the elemental resistance reduction provided by Azusa with
                Dusk Radiance.
              </li>
              <li>
                <AshCharacter
                  slug="yuqi"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />{' '}
                <strong>[Tank]</strong> - A reliable tank and currently the only
                way to trigger “My Vision is Clear!”. You can bring him for wet
                maps and/or if using Sambheka or Furay & Furney.
              </li>
              <li>
                <AshCharacter
                  slug="furay-and-furney"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />{' '}
                <strong>[Elemental Zone]</strong> - Currently the most reliable
                source of large water zones to exploit the +100% DMG from “My
                Vision is Clear!”. You can swap them out for Azusa or anyone
                else after placing the water zone.
              </li>
              <li>
                <AshCharacter
                  slug="su-xiao"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />{' '}
                <strong>[Support]</strong> - Budget healer, but always reliable
                for her “revive” skill and buff. Su Xiao’s Max HP buff from her
                ultimate can also help the naturally squishy Wind team.
              </li>
            </ul>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="Memory Trace Recommendations" />
              <h5>Best in Slot</h5>
              <ul>
                <li>
                  <AshTrace name="Blood Ruvia - Secret" mode="data" onProfile />{' '}
                  [Core]
                </li>
                <li>
                  <AshTrace name="Dusk Radiance" mode="data" onProfile /> [Core]
                </li>
                <li>
                  <AshTrace name="Born From Ashes" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Secret Melody" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Going Off-Course" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Karma" mode="data" onProfile />
                </li>
              </ul>
              <h5>Alternatives and/or Budget options</h5>
              <ul>
                <li>
                  <AshTrace name="Muscle Building" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace
                    name="Beneath The Deception"
                    mode="data"
                    onProfile
                  />
                </li>
                <li>
                  <AshTrace name="Emptiness" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Happy Hours" mode="data" onProfile />{' '}
                  (Replaces “Secret Melody”)
                </li>
                <li>
                  <AshTrace name="Home" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Dawn" mode="data" onProfile /> (if using
                  Sambheka)
                </li>
                <li>
                  <AshTrace name="My Vision Is Clear!" mode="data" onProfile />{' '}
                  (If using Yuqi + Water Zones)
                </li>
                <li>
                  <AshTrace name="Behind The Curtains" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace
                    name="Curiosity Of A Hailinian"
                    mode="data"
                    onProfile
                  />{' '}
                  (For the Triangle skills, but becomes a lot better with Thea)
                </li>
                <li>
                  <AshTrace name="Growth" mode="data" onProfile />
                </li>
              </ul>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="Future Synergies" />
              <div className="employees-container-card-mode-ash">
                <Card className="avatar-card">
                  <AshCharacter
                    slug="boreas"
                    mode="card"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <p>
                <strong>[Buffer/Support]</strong> - Boreas’ shields and Crit
                buffs add to the balance of the already deadly Wind Team, no
                matter where each teammate is. He can also enhance his allies’
                basic attacks with Ice, widening the possibilities of the team
                by making it easier for elemental reactions like Melt to join
                the mix.
              </p>
              <div className="employees-container-card-mode-ash">
                <Card className="avatar-card">
                  <AshCharacter
                    slug="thea"
                    mode="card"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <p>
                <strong>[Specialist]</strong> - Thea is an enabler of “Curiosity
                of a Hailinian”, as well as a well-rounded utility character
                that, when played well, can make any team nearly immortal. In
                this case, she benefits greatly from the Dusk Radiance debuff
                applied by Azusa, all while providing respectable DPS and stuns.
              </p>
              <div className="employees-container-card-mode-ash">
                <Card className="avatar-card">
                  <AshCharacter
                    slug="elpis"
                    mode="card"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <p>
                <strong>[Buffer]</strong>- Elpis becomes the Leader of the Wind
                team in future updates. She brings a lot to the table, from
                debuffs to buffs to healing to damage, she has it all. It’s
                highly recommended to get her first dupe for the +50% CD
                Recovery on active skills and +25% ATK for all allies under her
                field effect.
              </p>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h5>Jump to</h5>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Review</a>
                </li>
                <li>
                  <a href="#section-2">Gameplay Tips</a>
                </li>
                <li>
                  <a href="#section-3">Team Recommendation</a>
                </li>
                <li>
                  <a href="#section-4">Memory Trace Recommendations</a>
                </li>
                <li>
                  <a href="#section-5">Future Synergies</a>
                </li>
              </ul>
            </ScrollspyNav>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AshGuidesSYPScar;

export const Head: React.FC = () => (
  <Seo
    title="Should You Pull Scarlett | Ash Echoes | Prydwen Institute"
    description="A dedicated guide for Scarlett that will help you decide whether to pull her or not."
    game="ash"
  />
);
